import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import {
  Box,
  List,
  ListSubheader,
  ListItem,
  ListItemButton,
  ListItemContent,
  Typography,
  Select,
  Button,
  Option,
  Sheet,
  Table,
} from "@mui/joy";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import index from "../index.json";
import { apiRequest } from "../Utils";
import FilterableList from "../components/FilterableList";
import { Experimental_CssVarsProvider } from "@mui/material/styles";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts";
import { secondsToMinutes } from "../Utils";
import RegisterModal from "../components/RegisterModal";
import Footer from "../components/Footer";

const Home = () => {
  const videoPlayerRef = useRef(null);
  const headerRef = useRef(null);
  const [activeVideo, setActiveVideo] = useState(
    index.videos_to_be_annotated[0]
  );
  const [timelineData, setTimelineData] = useState([]);
  const [techniqueToColorMap, setTechniqueToColorMap] = useState([]);
  const [analysisData, setAnalysisData] = useState({});
  const [actionIdMap, setActionIdMap] = useState({});
  const [activeSubject, setActiveSubject] = useState("0");
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [sayModalSorry, setSayModalSorry] = useState(false);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const pieChartColors = [
    "#0088fe",
    "#00c49f",
    "#e82858",
    "#dcb5fd",
    "#2a8d2e",
    "#de9f59",
    "#5b62e1",
    "#28b6c7",
    "#67e90d",
    "#6ed4af",
    "#4140bc",
  ];

  useEffect(() => {
    apiRequest(
      "GET",
      "https://coathlete-api.onrender.com/getActionData/" + activeVideo,
      {}
    ).then((actionData) => {
      apiRequest(
        "GET",
        "https://coathlete-api.onrender.com/getAnalysis/" + activeVideo,
        {}
      ).then((analysisData) => {
        setActionIdMap(actionData);
        let timelineDataArray = [];
        setAnalysisData(analysisData);
        analysisData.subjects.forEach((subject) => {
          let timelineDataObject = {};
          timelineDataObject["name"] = subject.name;
          timelineDataObject["actions"] = [];
          subject.actions.forEach((id) => {
            timelineDataObject["actions"].push(actionData[id]);
          });
          timelineDataObject["actions"] = timelineDataObject["actions"].sort(
            (a, b) => a.timestamp - b.timestamp
          );
          timelineDataArray.push(timelineDataObject);
        });
        setTimelineData(timelineDataArray);
      });
    });
  }, [activeVideo]);

  const readableType = (type, capitalize = true) => {
    // Split the string by underscore
    let words = type.split("_");

    // Check if the last word is "left" or "right"
    let direction = "";
    if (
      words[words.length - 1].toLowerCase() === "left" ||
      words[words.length - 1].toLowerCase() === "right"
    ) {
      direction = words.pop(); // Remove and store the direction
    }

    // Join the words by space
    let result = words.join(" ");

    // Add the direction to the start if it exists
    if (direction) {
      result = direction + " " + result;
    }

    // Capitalize the first letter if needed
    if (capitalize) {
      result = result.charAt(0).toUpperCase() + result.slice(1);
    }

    return result;
  };

  const getStanceCountsForPieChart = (subjectId) => {
    return [
      {
        label: "Orthodox",
        value:
          "orthodox" in analysisData.subjects[subjectId]["stance_counts"]
            ? analysisData.subjects[subjectId]["stance_counts"]["orthodox"]
            : 0,
        color: pieChartColors[0],
      },
      {
        label: "Southpaw",
        value:
          "southpaw" in analysisData.subjects[subjectId]["stance_counts"]
            ? analysisData.subjects[subjectId]["stance_counts"]["southpaw"]
            : 0,
        color: pieChartColors[1],
      },
    ];
  };

  const getTechniquesForPieChart = (subjectId) => {
    let data = [];
    if (
      analysisData?.subjects == null ||
      analysisData.subjects[subjectId] == null
    )
      return data;
    for (let category in analysisData.subjects[subjectId].strike_counts) {
      for (let type in analysisData.subjects[subjectId].strike_counts[
        category
      ]) {
        let thrown = 0;
        for (let target in analysisData.subjects[subjectId].strike_counts[
          category
        ][type]["target"]) {
          for (let outcome in analysisData.subjects[subjectId].strike_counts[
            category
          ][type]["target"][target]) {
            thrown +=
              analysisData.subjects[subjectId].strike_counts[category][type][
                "target"
              ][target][outcome];
          }
        }
        let temp = techniqueToColorMap;
        if (!(type in techniqueToColorMap)) {
          temp[type] = pieChartColors[data.length];
          setTechniqueToColorMap(temp);
        }
        const entry = {
          label: readableType(type), // todo - extract to util function
          value: thrown,
          color: techniqueToColorMap[type],
        };
        data.push(entry);
      }
    }
    return data;
  };

  const getTotalTechniques = (subjectId) => {
    if (
      analysisData?.subjects == null ||
      analysisData.subjects[subjectId] == null
    )
      return;
    let total = 0;
    if ("orthodox" in analysisData.subjects[activeSubject]["stance_counts"]) {
      total +=
        analysisData?.subjects[activeSubject]["stance_counts"]["orthodox"];
    }
    if ("southpaw" in analysisData.subjects[activeSubject]["stance_counts"]) {
      total +=
        analysisData.subjects[activeSubject]["stance_counts"]["southpaw"];
    }
    return total;
  };

  const getMostCommonTechnique = (subjectId, category = "Any") => {
    if (
      analysisData?.subjects == null ||
      analysisData.subjects[subjectId] == null
    )
      return "";

    category = category.toLowerCase();

    let mostCommon = "";
    let maxCount = 0;
    if (category === "any") {
      for (let category in analysisData.subjects[subjectId].strike_counts) {
        for (let type in analysisData.subjects[subjectId].strike_counts[
          category
        ]) {
          let count = 0;
          for (let target in analysisData.subjects[subjectId].strike_counts[
            category
          ][type]["target"]) {
            for (let outcome in analysisData.subjects[subjectId].strike_counts[
              category
            ][type]["target"][target]) {
              count +=
                analysisData.subjects[subjectId].strike_counts[category][type][
                  "target"
                ][target][outcome];
            }
          }
          if (maxCount < count) {
            maxCount = count;
            mostCommon = type;
          }
        }
      }
    } else {
      for (let type in analysisData.subjects[subjectId].strike_counts[
        category
      ]) {
        let count = 0;
        for (let target in analysisData.subjects[subjectId].strike_counts[
          category
        ][type]["target"]) {
          for (let outcome in analysisData.subjects[subjectId].strike_counts[
            category
          ][type]["target"][target]) {
            count +=
              analysisData.subjects[subjectId].strike_counts[category][type][
                "target"
              ][target][outcome];
          }
        }
        if (maxCount < count) {
          maxCount = count;
          mostCommon = type;
        }
      }
    }
    return mostCommon;
  };

  const getAccuracyForBarChart = (subjectId) => {
    let data = [];
    if (
      analysisData?.subjects == null ||
      analysisData.subjects[subjectId] == null
    )
      return data;
    for (let category in analysisData.subjects[subjectId].strike_counts) {
      for (let type in analysisData.subjects[subjectId].strike_counts[
        category
      ]) {
        let landed = 0;
        let thrown = 0;
        for (let target in analysisData.subjects[subjectId].strike_counts[
          category
        ][type]["target"]) {
          for (let outcome in analysisData.subjects[subjectId].strike_counts[
            category
          ][type]["target"][target]) {
            if (outcome === "landed") {
              landed +=
                analysisData.subjects[subjectId].strike_counts[category][type][
                  "target"
                ][target][outcome];
            }
            thrown +=
              analysisData.subjects[subjectId].strike_counts[category][type][
                "target"
              ][target][outcome];
          }
        }
        const entry = {
          landed: landed,
          thrown: thrown,
          type: readableType(type),
        };
        data.push(entry);
      }
    }
    return data;
  };

  const getMostAndLeastLandedStrike = (subjectId) => {
    if (
      analysisData?.subjects == null ||
      analysisData.subjects[subjectId] == null
    )
      return "";

    let mostLanded = "";
    let maxCount = 0;
    let maxThrownCount = Number.MAX_VALUE;
    let leastLanded = "";
    let minCount = Number.MAX_VALUE;
    let minThrownCount = 0;
    for (let category in analysisData.subjects[subjectId].strike_counts) {
      for (let type in analysisData.subjects[subjectId].strike_counts[
        category
      ]) {
        let landed = 0;
        let thrown = 0;
        for (let target in analysisData.subjects[subjectId].strike_counts[
          category
        ][type]["target"]) {
          for (let outcome in analysisData.subjects[subjectId].strike_counts[
            category
          ][type]["target"][target]) {
            if (outcome === "landed") {
              landed +=
                analysisData.subjects[subjectId].strike_counts[category][type][
                  "target"
                ][target][outcome];
            }
            thrown +=
              analysisData.subjects[subjectId].strike_counts[category][type][
                "target"
              ][target][outcome];
          }
        }
        if (
          minCount > landed ||
          (minCount === landed && thrown > minThrownCount)
        ) {
          minCount = landed;
          leastLanded = type;
          minThrownCount = thrown;
        }
        if (
          maxCount < landed ||
          (maxCount === landed && thrown < maxThrownCount)
        ) {
          maxCount = landed;
          mostLanded = type;
          maxThrownCount = thrown;
        }
      }
    }
    return [mostLanded, leastLanded];
  };

  const handleTimestampClick = (e, timestamp) => {
    if (videoPlayerRef == null) return;
    e.preventDefault();
    videoPlayerRef.current.seekTo(timestamp - 0.2);
    headerRef.current.scrollIntoView();
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", height: "100vh" }}>
        {/* Menu side */}
        <Box
          sx={{
            width: "250px",
            borderRight: "1px solid",
            borderColor: "divider",
            overflow: "hidden",
          }}
          display={{ xs: "none", lg: "inline-block" }}
        >
          <Box
            sx={{ px: 2, py: 1, display: "flex", alignItems: "center", gap: 1 }}
          >
            <Box
              component="img"
              src="logo.png" // Replace with your image path
              alt="Coathlete Logo"
              sx={{ width: 40, height: 40, borderRadius: "xs" }} // Adjust size as needed
            />
            <Typography level="h3" sx={{ my: 2, mx: 1 }}>
              Coathlete
            </Typography>
          </Box>
          <Box
            sx={{ px: 2, display: "flex", alignItems: "center", gap: 1 }}
          >
            <Typography level="h5" sx={{ my: 0, mx: 0 }}>
              <a href="#/about/">About</a>
            </Typography>
          </Box>
          <List>
            <ListSubheader>Videos</ListSubheader>
            {index.videos_to_be_annotated.map((video) => {
              return (
                <ListItem>
                  <ListItemButton
                    onClick={() => setActiveVideo(video)}
                    selected={activeVideo === video}
                  >
                    <ListItemContent>
                      <Typography level="body-sm">
                        Alvarez vs McGregor
                      </Typography>
                    </ListItemContent>
                  </ListItemButton>
                </ListItem>
              );
            })}
            <ListItem>
              <ListItemButton
                onClick={() => {
                  setSayModalSorry(true);
                  setOpenRegisterModal(true);
                }}
              >
                <ListItemContent> + Add video</ListItemContent>
              </ListItemButton>
            </ListItem>
          </List>
        </Box>

        {/* Content side */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            px: 8,
            py: 4,
            overflow: "auto",
          }}
        >
          <Typography level="h1" ref={headerRef}>
            Fight Analysis
          </Typography>
          <Typography level="h4">Alvarez vs McGregor</Typography>
          <Button
            sx={{
              display: { md: "inline-block", lg: "none" },
              width: 300,
              my: 1,
            }}
            onClick={() => {
              setSayModalSorry(true);
              setOpenRegisterModal(true);
            }}
          >
            + Analyze My Own Video
          </Button>
          {/* This Box contains the player and the lists */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              py: 2,
              gap: 2,
            }}
          >
            {/* React player */}
            <Box
              sx={{
                flex: { xs: 1, lg: 5 },
                width: "100%",
                mb: { xs: 2, lg: 0 },
                height: "420px",
              }}
            >
              {" "}
              {/* Fixed height */}
              <ReactPlayer
                url="https://coathlete-assets.s3.amazonaws.com/alvarez_vs_mcgregor.mp4"
                controls
                height="100%"
                width="100%"
                ref={videoPlayerRef}
                onProgress={(progress) => {
                  setPlayedSeconds(progress.playedSeconds);
                }}
                progressInterval={10}
              />
            </Box>

            {/* Right side lists */}
            <Box
              sx={{
                flex: { xs: 1, lg: 5 },
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
                gap: 2,
              }}
            >
              <FilterableList
                timelineData={timelineData == null ? null : timelineData[0]}
                color="danger.plainColor"
                videoPlayerRef={videoPlayerRef}
                playedSeconds={playedSeconds}
              ></FilterableList>
              <FilterableList
                timelineData={timelineData == null ? null : timelineData[1]}
                color="focusVisible"
                videoPlayerRef={videoPlayerRef}
                playedSeconds={playedSeconds}
              ></FilterableList>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              py: 2,
              gap: 2,
            }}
          >
            <Typography level="h2">Data Report for</Typography>
            <Select
              onChange={(_, newValue) => {
                setActiveSubject(newValue);
              }}
              defaultValue="0"
            >
              <Option value="0">Eddie Alvarez</Option>
              <Option value="1">Conor McGregor</Option>
            </Select>
          </Box>
          <Typography level="h3">Striking Statistics</Typography>
          {analysisData?.subjects != null && (
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
                alignItems: { xs: "center", lg: "flex-start" }, // Centers vertically for column and horizontally for row
                justifyContent: { xs: "center", lg: "space-between" }, // Added for spacing on lg screens
                py: 2,
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  py: 2,
                  gap: 2,
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    wordWrap: "break-word",
                  }}
                >
                  {analysisData.subjects[activeSubject].name.split(" ")[0]} is{" "}
                  {(!(
                    "orthodox" in
                    analysisData.subjects[activeSubject]["stance_counts"]
                  ) ||
                    ("southpaw" in
                      analysisData.subjects[activeSubject]["stance_counts"] &&
                      analysisData?.subjects[activeSubject]["stance_counts"][
                        "southpaw"
                      ] >
                        analysisData?.subjects[activeSubject]["stance_counts"][
                          "orthodox"
                        ])) && <>a southpaw</>}{" "}
                  {(!(
                    "southpaw" in
                    analysisData.subjects[activeSubject]["stance_counts"]
                  ) ||
                    ("orthodox" in
                      analysisData.subjects[activeSubject]["stance_counts"] &&
                      analysisData?.subjects[activeSubject]["stance_counts"][
                        "orthodox"
                      ] >
                        analysisData?.subjects[activeSubject]["stance_counts"][
                          "southpaw"
                        ])) && <>an orthodox</>}{" "}
                  fighter.
                </Typography>
                <Experimental_CssVarsProvider>
                  {analysisData?.subjects != null && (
                    <PieChart
                      series={[
                        {
                          outerRadius: 80,
                          arcLabel: (params) =>
                            `${(
                              (params.value /
                                getTotalTechniques(activeSubject)) *
                              100
                            ).toFixed(0)}%`,
                          arcLabelMinAngle: 10,
                          data: getStanceCountsForPieChart(activeSubject),
                        },
                      ]}
                      sx={{
                        [`& .${pieArcLabelClasses.root}`]: {
                          fill: "white",
                          fontSize: 12,
                        },
                        alignSelf: "flex-start",
                      }}
                      width={330}
                      height={200}
                    />
                  )}
                </Experimental_CssVarsProvider>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  py: 2,
                  gap: 2,
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    wordWrap: "break-word",
                  }}
                >
                  His most thrown punch is the{" "}
                  {readableType(
                    getMostCommonTechnique(activeSubject, "punch"),
                    false
                  )}
                  , and his most thrown kick is the{" "}
                  {readableType(
                    getMostCommonTechnique(activeSubject, "kick"),
                    false
                  )}
                  .
                </Typography>
                <Experimental_CssVarsProvider>
                  <PieChart
                    series={[
                      {
                        outerRadius: 80,
                        arcLabel: (params) => {
                          return `${(
                            (params.value / getTotalTechniques(activeSubject)) *
                            100
                          ).toFixed(0)}%`;
                        },
                        arcLabelMinAngle: 10,
                        data: getTechniquesForPieChart(activeSubject),
                      },
                    ]}
                    sx={{
                      [`& .${pieArcLabelClasses.root}`]: {
                        fill: "white",
                        fontSize: 12,
                      },
                      alignSelf: "flex-start",
                    }}
                    width={560}
                    height={200}
                  />
                </Experimental_CssVarsProvider>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  py: 2,
                  gap: 2,
                  width: "100%", // Ensure the box takes the full width of its parent
                }}
              >
                <Typography
                  sx={{
                    wordWrap: "break-word",
                  }}
                >
                  His most landed strike is the{" "}
                  {readableType(
                    getMostAndLeastLandedStrike(activeSubject)[0],
                    false
                  )}
                  , and his least landed strike is the{" "}
                  {readableType(
                    getMostAndLeastLandedStrike(activeSubject)[1],
                    false
                  )}
                  .
                </Typography>

                <Experimental_CssVarsProvider>
                  <BarChart
                    dataset={getAccuracyForBarChart(activeSubject)}
                    xAxis={[
                      {
                        label: "count",
                      },
                    ]}
                    yAxis={[{ scaleType: "band", dataKey: "type" }]}
                    series={[
                      { dataKey: "landed", label: "Landed" },
                      { dataKey: "thrown", label: "Thrown" },
                    ]}
                    width={400}
                    height={300}
                    sx={{
                      [`.${axisClasses.left} .${axisClasses.tickLabel}`]: {
                        transform: "translate(4px, 0)",
                        fontSize: "9px !important",
                      },
                      [`.${axisClasses.left} `]: {
                        width: 500,
                      },
                    }}
                    layout="horizontal"
                  />
                </Experimental_CssVarsProvider>
              </Box>
            </Box>
          )}
          <Typography level="h3">Combinations</Typography>
          {analysisData?.subjects != null && (
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
                alignItems: { xs: "center", lg: "flex-start" }, // Centers vertically for column and horizontally for row
                justifyContent: { xs: "center", lg: "space-between" }, // Added for spacing on lg screens
                paddingBottom: 2,
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingBottom: 2,
                  gap: 2,
                  width: "100%",
                }}
              >
                <Sheet>
                  <Table>
                    <thead>
                      <tr>
                        <th>Combinations</th>
                        <th>Timestamps</th>
                        <th>Occurences</th>
                      </tr>
                    </thead>
                    <tbody>
                      {analysisData?.subjects[activeSubject].combinations.map(
                        (row) => (
                          <tr key={row.combination.join(",")}>
                            <td>
                              {row.combination
                                .map((x) => readableType(x, false))
                                .join(", ")}
                            </td>
                            <td>
                              {row.occurrences.map((x, index) => (
                                <>
                                  <a
                                    href="#"
                                    onClick={(e) =>
                                      handleTimestampClick(
                                        e,
                                        actionIdMap[x[0]].timestamp
                                      )
                                    }
                                  >
                                    {secondsToMinutes(
                                      actionIdMap[x[0]].timestamp
                                    )}
                                  </a>
                                  {index != row.occurrences.length - 1 && (
                                    <>, </>
                                  )}
                                </>
                              ))}
                            </td>
                            <td>{row.occurrences.length}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </Sheet>
              </Box>
            </Box>
          )}
          <Typography level="h3">Vulnerabilities</Typography>
          {analysisData?.subjects != null && (
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
                alignItems: { xs: "center", lg: "flex-start" }, // Centers vertically for column and horizontally for row
                justifyContent: { xs: "center", lg: "space-between" }, // Added for spacing on lg screens
                paddingBottom: 2,
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingBottom: 2,
                  gap: 2,
                  width: "100%",
                }}
              >
                <Sheet>
                  <Table>
                    <thead>
                      <tr>
                        <th>Technique/Combination</th>
                        <th>Vulnerabilities</th>
                        <th>Timestamps</th>
                        <th>Occurences</th>
                      </tr>
                    </thead>
                    <tbody>
                      {analysisData?.subjects[activeSubject].vulnerabilities
                        .filter(
                          (row) => !row.combination.join(",").includes("kick")
                        )
                        .sort(
                          (a, b) => b.occurrences.length - a.occurrences.length
                        )
                        .map((row) => (
                          <tr
                            key={
                              row.vulnerabilities[0] +
                              "_" +
                              row.combination.join(", ")
                            }
                          >
                            <td>{row.combination.join(", ")}</td>
                            <td>
                              {row.vulnerabilities
                                .map((x) => readableType(x, false))
                                .join(", ")}
                            </td>
                            <td>
                              {row.occurrences.map((x, index) => (
                                <>
                                  <a
                                    href="#"
                                    onClick={(e) =>
                                      handleTimestampClick(
                                        e,
                                        actionIdMap[x[0]].timestamp
                                      )
                                    }
                                  >
                                    {secondsToMinutes(
                                      actionIdMap[x[0]].timestamp
                                    )}
                                  </a>
                                  {index != row.occurrences.length - 1 && (
                                    <>, </>
                                  )}
                                </>
                              ))}
                            </td>
                            <td>{row.occurrences.length}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Sheet>
              </Box>
            </Box>
          )}
          <Footer
            setOpenRegisterModal={() => {
              setSayModalSorry(false);
              setOpenRegisterModal(true);
            }}
          />
        </Box>
        <RegisterModal
          open={openRegisterModal}
          setOpen={setOpenRegisterModal}
          sayModalSorry={sayModalSorry}
        />
      </Box>
    </>
  );
};

export default Home;
