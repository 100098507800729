import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  List,
  ListSubheader,
  ListItem,
  ListItemButton,
  Button,
  Modal,
  Sheet,
  Typography,
  Select,
  Option,
} from "@mui/joy";
import { red, green } from "@mui/material/colors";
import { secondsToMinutes } from "../Utils";
import FilterListIcon from "@mui/icons-material/FilterList";

const FilterableList = (props) => {
  const [filterSide, setFilterSide] = useState("Any");
  const [filterCategory, setFilterCategory] = useState("Any");
  const [filterPunch, setFilterPunch] = useState("Any");
  const [filterKick, setFilterKick] = useState("Any");
  const [items, setItems] = useState(null);
  const [open, setOpen] = useState(false);
  const [focusIndex, setFocusIndex] = useState(0);
  const listRef = useRef(null);
  const itemRef = useRef(null);

  const applyFilter = () => {
    let items = props.timelineData?.actions;
    if (filterSide && filterSide.toLowerCase() !== "any") {
      const filteredItems = items.filter(
        (item) => item.side === filterSide.toLowerCase()
      );
      items = filteredItems;
    }

    if (filterCategory && filterCategory.toLowerCase() !== "any") {
      const filteredItems = items.filter(
        (item) => item.category === filterCategory.toLowerCase()
      );
      items = filteredItems;
    }

    if (
      filterCategory.toLowerCase() === "punch" &&
      filterPunch &&
      filterPunch.toLowerCase() !== "any"
    ) {
      const filteredItems = items.filter(
        (item) => item.type === filterPunch.toLowerCase()
      );
      items = filteredItems;
    }

    if (
      filterCategory.toLowerCase() === "kick" &&
      filterKick &&
      filterKick.toLowerCase() !== "any"
    ) {
      const filteredItems = items.filter(
        (item) => item.type === filterKick.toLowerCase()
      );
      items = filteredItems;
    }

    setItems(items);
    setOpen(false);
  };

  const resetFilter = () => {
    setFilterSide("Any");
    setFilterCategory("Any");
    setFilterPunch("Any");
    setFilterKick("Any");
    setItems(props.timelineData?.actions);
    setOpen(false);
  };

  const handleTimestampClick = (e, timestamp) => {
    if (props.videoPlayerRef == null) return;
    e.preventDefault();
    window.scrollTo(0, 0);
    props.videoPlayerRef.current.seekTo(timestamp - 0.2);
  };

  const focusOnItem = (index) => {
    if (itemRef?.current == null) return;
    const LIST_ITEM_HEIGHT = itemRef.current.clientHeight;

    const amountToScroll = LIST_ITEM_HEIGHT * index;
    listRef.current.scrollTo(0, amountToScroll);
  };

  useEffect(() => {
    if (items == null) {
      setItems(props.timelineData?.actions);
    }
    if (items != null && focusIndex < items.length) {
      let index = 0;
      while (
        index < items.length &&
        items[index].timestamp < props.playedSeconds
      ) {
        ++index;
      }
      focusOnItem(index);
      setFocusIndex(index);
    }
  }, [focusIndex, items, props.playedSeconds, props.timelineData]);

  return (
    <>
      <List
        variant="outlined"
        sx={{ overflow: "auto", maxHeight: { xs: 420, lg: 420 } }}
        ref={listRef}
      >
        <ListItem sticky>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
            }}
          >
            <ListSubheader sx={{ color: props.color }} sticky>
              {props.timelineData?.name}
            </ListSubheader>
            <Button onClick={() => setOpen(true)} fullWidth variant="outlined">
              <FilterListIcon sx={{ mx: 1 }} />
              Filter
            </Button>
          </Box>
        </ListItem>
        {items?.map((item, index) => (
          <ListItem
            ref={index === 0 ? itemRef : null}
            sx={{
              backgroundColor:
                index === focusIndex ? "background.level1" : "background.body",
            }}
          >
            <ListItemButton
              key={index}
              sx={{ display: "flex", alignItems: "center", width: "100%" }}
              onClick={(e) => handleTimestampClick(e, item.timestamp)}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {/* Left-aligned elements */}
                <Typography level="body-xs" sx={{ fontWeight: "bold" }}>
                  {secondsToMinutes(item.timestamp)}
                </Typography>
                <Typography
                  level="body-xs"
                  sx={{
                    fontWeight: "bold",
                    color: item.dropped ? green[700] : "black",
                  }}
                >
                  {item.side.charAt(0).toUpperCase() + item.side.substring(1)}{" "}
                  {item.type.split("_").join(" ")}
                </Typography>
                <Typography level="body-xs">
                  To the{" "}
                  {item.target.split("_").length === 2
                    ? item.target.split("_")[1] +
                      " " +
                      item.target.split("_")[0]
                    : item.target}
                </Typography>
              </Box>

              {/* Right-aligned element */}
              <Box sx={{ ml: "auto" }}>
                {" "}
                {/* This pushes the icon to the right */}
                <Typography
                  level={"body-xs"}
                  sx={{
                    display: "flex",
                    alignItems: "center", // Aligns items vertically in the center
                    gap: 1, // Optional: adds a small gap between text and icon
                    color: item.outcome === "landed" ? green[700] : red[700],
                  }}
                >
                  {item.dropped ? "dropped opponent" : item.outcome}
                </Typography>
              </Box>
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Sheet
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography>Show me</Typography>

          <Box sx={{ display: "flex", gap: 2 }}>
            <Select
              name={"side"}
              placeholder={"any side"}
              value={
                filterSide.toLowerCase() === "any"
                  ? null
                  : filterSide.toLowerCase()
              }
              onChange={(e) => {
                setFilterSide(e.target.textContent.toLowerCase());
              }}
            >
              <Option value="left">Left</Option>
              <Option value="right">Right</Option>
              <Option value="any">Any</Option>
            </Select>

            <Select
              name={"category"}
              placeholder={"any type"}
              value={
                filterCategory.toLowerCase() === "any"
                  ? null
                  : filterCategory.toLowerCase()
              }
              onChange={(e) => {
                setFilterCategory(e.target.textContent.toLowerCase());
              }}
            >
              <Option value="punch">Punch</Option>
              <Option value="kick">Kick</Option>
              <Option value="knee">Knee</Option>
              <Option value="elbow">Elbow</Option>
              <Option value="any">Any</Option>
            </Select>

            {filterCategory.toLowerCase() === "punch" && (
              <Select
                name={"type"}
                placeholder={"any punch"}
                value={
                  filterPunch.toLowerCase() === "any"
                    ? null
                    : filterPunch.toLowerCase()
                }
                onChange={(e) => {
                  setFilterPunch(e.target.textContent.toLowerCase());
                }}
              >
                <Option value="jab">Jab</Option>
                <Option value="cross">Cross</Option>
                <Option value="hook">Hook</Option>
                <Option value="uppercut">Uppercut</Option>
                <Option value="overhand">Overhand</Option>
                <Option value="any">Any</Option>
              </Select>
            )}

            {filterCategory.toLowerCase() === "kick" && (
              <Select
                name={"type"}
                placeholder={"any kick"}
                value={
                  filterKick.toLowerCase() === "any"
                    ? null
                    : filterKick.toLowerCase()
                }
                onChange={(e) => {
                  setFilterKick(
                    e.target.textContent
                      .toLowerCase()
                      .toLowerCase()
                      .split(" ")
                      .join("_")
                  );
                }}
              >
                <Option value="swing_kick">Swing Kick</Option>
                <Option value="front_kick">Front Kick</Option>
                <Option value="side_kick">Side Kick</Option>
                <Option value="any">Any</Option>
              </Select>
            )}
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-start", gap: 2 }}>
            <Button onClick={resetFilter}>Reset</Button>
            <Button onClick={applyFilter} variant="solid">
              Apply
            </Button>
          </Box>
        </Sheet>
      </Modal>
    </>
  );
};

export default FilterableList;
