import React from "react";
import {
  ModalDialog,
  Modal,
  ModalClose,
  Typography,
  Box,
  Button,
} from "@mui/joy";
import { useAuth0 } from "@auth0/auth0-react";

const RegisterModal = (props) => {
  const { loginWithRedirect } = useAuth0();
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={props.open}
      onClose={() => props.setOpen(false)}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ModalDialog>
        <ModalClose />
        <Box
          sx={{ px: 2, py: 1, display: "flex", alignItems: "center", gap: 1 }}
        >
          <Box
            component="img"
            src="../logo.png" // Replace with your image path
            alt="Coathlete Logo"
            sx={{ width: 40, height: 40, borderRadius: "sm" }} // Adjust size as needed
          />
          <Typography level="h3" sx={{ my: 2, mx: 1 }}>
            Coathlete
          </Typography>
        </Box>

        {props.sayModalSorry && (
          <Typography
            sx={{
              wordWrap: "break-word",
              textAlign: "center",
            }}
          >
            Sorry! That isn't available yet - but it's coming soon.
          </Typography>
        )}
        <Typography
          sx={{
            wordWrap: "break-word",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Sign up now to get exclusive early access to custom videos and
          advanced analytics when they become available.
        </Typography>
        <Button onClick={() => loginWithRedirect()}>Create an Account</Button>
        <Typography
          sx={{
            wordWrap: "break-word",
            textAlign: "center",
          }}
        >
          Make sure to join our{" "}
          <a target="_blank" href="https://discord.gg/RV6B6Es6JX">
            Discord group
          </a>{" "}
          to provide feedback and stay in the loop!
        </Typography>
      </ModalDialog>
    </Modal>
  );
};

export default RegisterModal;
