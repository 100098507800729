import React, { useState } from "react";
import { Box, Typography } from "@mui/joy";
import RegisterModal from "../components/RegisterModal";
import Footer from "../components/Footer";
import { useAuth0 } from "@auth0/auth0-react";

const About = () => {
  const { loginWithRedirect } = useAuth0();
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [sayModalSorry, setSayModalSorry] = useState(false);
  
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", height: "100vh" }}>
        {/* Menu side */}
        <Box
          sx={{
            width: "250px",
            borderRight: "1px solid",
            borderColor: "divider",
            overflow: "hidden",
          }}
          display={{ xs: "none", lg: "inline-block" }}
        >
          <Box
            sx={{ px: 2, py: 1, display: "flex", alignItems: "center", gap: 1 }}
          >
            <Box
              component="img"
              src="../logo.png" // Replace with your image path
              alt="Coathlete Logo"
              sx={{ width: 40, height: 40, borderRadius: "xs" }}
            />
            <Typography level="h3" sx={{ my: 2, mx: 1 }}>
              Coathlete
            </Typography>
          </Box>
          <Box
            sx={{ px: 2, display: "flex", alignItems: "center", gap: 1 }}
          >
            <Typography level="h5" sx={{ my: 0, mx: 0 }}>
              <a href="/">Back to Home</a>
            </Typography>
          </Box>
        </Box>

        {/* Content side */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            px: 8,
            py: 4,
            overflow: "auto",
          }}
        >
          <Typography level="h2" sx={{ mb: 3 }}>
            About Coathlete
          </Typography>

          <Typography level="body1" sx={{ mb: 4 }}>
            Coathlete is a web and mobile platform designed to enhance athlete performance by 
            providing automatic analysis on user-provided sports video - specifically, combat sports. We boost
            athlete performance with immediate, actionable, and crucial insights that are 
            impossible to identify manually.
          </Typography>

          {/* Features Section */}
          <Typography level="h4" sx={{ mb: 2 }}>
            Key Features
          </Typography>
          <ul>
            <li><Typography level="body1">Automatic video analysis of sport video</Typography></li>
            <li><Typography level="body1">Actionable insights for instant adjustments</Typography></li>
            <li><Typography level="body1">Multi-video data aggregation and analysis</Typography></li>
            <li><Typography level="body1">Many more to come!</Typography></li>
          </ul>

          {/* Call to Action */}
          <Box sx={{ mt: 4 }}>
            <Typography level="h5" sx={{ mb: 2 }}>
              Ready to boost your performance?
            </Typography>
            <Typography level="body1">
              <a href="#" onClick={() => loginWithRedirect()}>Sign up now</a> to get early access and start improving your game with Coathlete's powerful analysis when it's available.
            </Typography>
          </Box>

          <Footer
            setOpenRegisterModal={() => {
              setSayModalSorry(false);
              setOpenRegisterModal(true);
            }}
          />
        </Box>

        <RegisterModal
          open={openRegisterModal}
          setOpen={setOpenRegisterModal}
          sayModalSorry={sayModalSorry}
        />
      </Box>
    </>
  );
};

export default About;
