export const apiRequest = async (method, url = "", data = {}) => {
  // Default options are marked with *
  var requestJSON = {
    method: method, // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  };
  if (method === "GET") {
    delete requestJSON.body;
  }

  const response = await fetch(url, requestJSON);
  return response.json(); // parses JSON response into native JavaScript objects
};

const stringPadLeft = (string, pad, length) => {
  return (new Array(length + 1).join(pad) + string).slice(-length);
};

export const secondsToMinutes = (time) => {
  time = Math.floor(time);
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return stringPadLeft(minutes, "0", 2) + ":" + stringPadLeft(seconds, "0", 2);
};
