import * as React from "react";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import { FaDiscord } from "react-icons/fa";
import { RiAccountCircleLine } from "react-icons/ri";
import { CiMail } from "react-icons/ci";

const FooterIcons = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        py: 2, // padding top and bottom
      }}
    >
      <a target="_blank" href="https://discord.gg/RV6B6Es6JX">
        <IconButton
          aria-label="Discord"
          sx={{
            mx: 1, // margin left and right
          }}
        >
          <FaDiscord />
        </IconButton>
      </a>
      <a target="_blank">
        <IconButton
          aria-label="Account"
          sx={{
            mx: 1, // margin left and right
          }}
          onClick={() => props.setOpenRegisterModal(true)}
        >
          <RiAccountCircleLine />
        </IconButton>
      </a>
      <a target="_blank" href="mailto:russell@coathlete.com">
        <IconButton
          aria-label="Mail"
          sx={{
            mx: 1, // margin left and right
          }}
        >
          <CiMail />
        </IconButton>
      </a>
    </Box>
  );
};

export default FooterIcons;
