import { HashRouter, Routes, Route } from "react-router-dom";
import Demo from "./pages/Demo";
import About from "./pages/About";
import Annotate from "./pages/Annotate";
import "./App.css";

function App() {
  return (
    <HashRouter>
      <Routes>
        {/* Define routes separately to avoid conflicts */}
        <Route path="/" element={<Demo />} exact />
        <Route path="/about" element={<About />} />
        {/* <Route path="/annotate" element={<Annotate />} /> */}
      </Routes>
    </HashRouter>
  );
}

export default App;
